.Footer {
  width: 100%;
  background: #222;
  font-size: 16px;

  .content {
    margin: 0 auto;
    padding: 16px 32px;
    max-width: 1320px;
    text-align: left;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }

  .top-links {
    display: flex;
    padding-bottom: 48px;

    div {
      flex: 3;
      width: 30%;
      color: #ccc;
    }

    div + div {
      margin-left: 16px;
    }
  }

  .separator {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .bottom-links {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 32px 0px;
    padding-bottom: 48px;
  }

  .legal-links {
    font-weight: 700;
    a + a {
      margin-left: 16px;
    }
  }
}
