ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  // height: 40vmin;

  p {
    padding: 14px;
  }
}

.App-header {
  background-color: #343434;
  // background: linear-gradient(#343434, #e3e3e3);
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
