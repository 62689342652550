.Main-Screen {
  background: #343434;
  // background: linear-gradient(#322b2b, #61549d);
  // background: linear-gradient(132deg, #3e3738, #591BC5, #212335);
  // animation: gradient 15s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // max-width: 800px;
  // margin: 0 auto;
  padding: 8px 0;
  // min-width: 800px;
  width: 100%;
  // height: 100%;
  //   height: 120vh;
  margin-top: 65px;
  // border-radius: 6px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.33);

  .ghost-components {
    // height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background: rgba(255, 255, 255, 0.2);
    // max-width: 1024px;
    // padding: 32px 16px;
    // margin: 0 auto;
  }

  ul {
    max-width: 1024px;
    padding: 16px;
    margin: 0 auto;
    li {
      width: 100%;
      margin-bottom: 18px;
    }
  }
  /* Animate Background*/
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

}

