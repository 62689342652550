.Global-Header {
  width: 100%;
  background: #222;
  left: 0;
  top: 0;
  color: white;
  height: 65px;
  position: absolute;

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    font-size: 18px;
  }

  .nav-item {
    color: white;
    padding: 8px 16px;

    a {
      color: white;
      text-decoration: none;

      &.box {
        border: 1px solid white;
        padding: 4px 16px;
        text-decoration: none;
      }
    }

    &.divider {
      flex: 2;
      text-align: right;
    }
  }

  .nav-item + .nav-item {
    margin-left: 8px;
  }

  .hero-image {
    background: #222;
  }

  .login-button {
    color: rgb(10, 255, 255);
  }

  img.hero-image {
    border-radius: 50%;
    border: 2px solid grey;
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
  // remove the text in mobile
}

@media only screen and (max-width: 600px) {
  .Global-Header {
    #timurista-name {
      display: none;
   }
  }
}


